<template>
  <b-container fluid>
    <b-row v-if="isLoading">loading....</b-row>
    <b-row>
      <b-col>
        Всего артикулов: {{ stats.all || 'loading...'}}, с баркодами: {{stats.compare || 'loading...'}}
<!--        <b-button type="button" size="sm" variant="info" @click="getStats">обновить</b-button>-->
      </b-col>
    </b-row>

    <div class="sticky-top" style="background-color: #fff">
      <b-overlay :show="isLoading" rounded="sm">
    <b-row class="mt-2 mb-2">
      <b-col sm="12">
          <b-alert v-if="lastId" variant="success" show>Последнее: {{last}}  <b-badge style="cursor: pointer" variant="danger" @click="sendCancel(lastId)">отменить</b-badge></b-alert>
        </b-col>
      <b-col sm="4" style="align-self: center">

        <div class="mb-2" style="display: flex;flex-direction: column;align-items: center;">
          <h5> <a :href="`https://www.wildberries.ru/catalog/${one.art_wb}/detail.aspx?targetUrl=GP`" target="_blank">{{one.name}} [{{one.art_wb}}]</a></h5>
          <img :src=one.imgLink alt="" width="200px">
        </div>


      </b-col>
      <b-col sm="8" class="d-none d-sm-block">
        <b-table-simple hover small responsive>
          <b-tbody>
            <b-tr v-for="o in one.options" :key="o.art_wb">
              <b-th>{{o.name}}</b-th>
              <b-th>{{ o.value }}</b-th>
            </b-tr>
          </b-tbody>
        </b-table-simple>
        <b-button type="button" size="sm" variant="danger" @click="sendErr()" :disabled="isSending" class="mr-1 mb-1">Не получилось найти</b-button>
        <b-button type="button" size="sm" variant="primary" @click="sendSkip()" :disabled="isSending" class="mr-1 mb-1">Пропустить</b-button>
        <b-button type="button" size="sm" variant="info" @click="sendNeedAdd()" :disabled="isSending" class="mr-1 mb-1">Нет в нашей бд</b-button>

      </b-col>
    </b-row>
      </b-overlay>
    </div>
    <b-row style="border-top: 2px solid #e7e7e7">
      <b-col sm="12" class="pb-2 mt-2 mb-2" style="border-bottom: 1px solid #e7e7e7">
        популярные:
<!--        <b-form-input size="sm" v-model="findModel" placeholder="поиск"></b-form-input>-->
        <b-badge
            variant="secondary"
            class="mr-1"
            style="cursor: pointer"
            v-for="m of mostPopular"
            :key="m+'100'"
            v-on:click="selModel=m, getVip(m)"
        >
          {{ m }}
        </b-badge>


      </b-col>
      <b-col sm="12" class="pb-2 mt-2 mb-2" style="border-bottom: 1px solid #e7e7e7" v-if="mostMaybe.length>0">
        возможные:
<!--        <b-form-input size="sm" v-model="findModel" placeholder="поиск"></b-form-input>-->
        <b-badge
            variant="secondary"
            class="mr-1"
            style="cursor: pointer"
            v-for="m of mostMaybe"
            :key="m+'200'"
            v-on:click="selModel=m, getVip(m)"
        >
          {{ m }}
        </b-badge>


      </b-col>
      <b-col>
        <b-row v-if="filteredModels.length>0">
<!--          <b-col md="4" lg="3" xl="2" v-for="(model,indx) of filteredModels" :key="model">-->
<!--              <b-button-->
<!--                  type="button"-->
<!--                  variant="outline-primary "-->
<!--                  v-on:click="selModel=model, getVip(model)"-->
<!--                  size="sm"-->
<!--                  class="mr-1 mb-1"-->
<!--                  style="text-align: start;width: 100%"-->
<!--                  :disabled="isLoading"-->
<!--                  :class="{'active': selModel===model, 'isLoading': isLoading}"-->
<!--              >{{indx}} - {{ model }}</b-button>-->
<!--          </b-col>-->
          <b-col sm="4">
            <div v-for="(model,indx) of filteredModels.slice(0,35)" :key="model">
              <b-button
                  type="button"
                  variant="outline-primary "
                  v-on:click="selModel=model, getVip(model)"
                  size="sm"
                  class="mr-1 mb-1"
                  style="text-align: start;width: 100%"
                  :disabled="isLoading"
                  :class="{'active': selModel===model, 'isLoading': isLoading}"
              >{{indx}} - {{ model }}</b-button>
            </div>
          </b-col>
          <b-col sm="4">
            <div v-for="(model,indx) of filteredModels.slice(35,70)" :key="model">
              <b-button
                  type="button"
                  variant="outline-primary "
                  v-on:click="selModel=model, getVip(model)"
                  size="sm"
                  class="mr-1 mb-1"
                  style="text-align: start;width: 100%"
                  :disabled="isLoading"
                  :class="{'active': selModel===model, 'isLoading': isLoading}"
              >{{indx}} - {{ model }}</b-button>
            </div>
          </b-col>
          <b-col sm="4">
            <div v-for="(model,indx) of filteredModels.slice(70,200)" :key="model">
              <b-button
                  type="button"
                  variant="outline-primary "
                  v-on:click="selModel=model, getVip(model)"
                  size="sm"
                  class="mr-1 mb-1"
                  style="text-align: start;width: 100%"
                  :disabled="isLoading"
                  :class="{'active': selModel===model, 'isLoading': isLoading}"
              >{{indx}} - {{ model }}</b-button>
            </div>
          </b-col>

        </b-row>
        <b-row v-else>не найдено</b-row>
        <b-row v-if="selModel" id="barabar" style="min-height: 2000px">
<!--        <b-row id="barabar">-->
          <b-col>
            <b-table-simple hover small responsive>
              <b-tbody>
                <b-tr v-for="v in vip" :key="v.vipName + v.barcode" style="cursor: pointer">
                  <b-th>{{v.vipName}}
                    <a v-for="art_wb of v.art_wb" :key="'art-wb'+art_wb" :href="`https://www.wildberries.ru/catalog/${art_wb}/detail.aspx?targetUrl=GP`" target="_blank">
<!--                    <a href="#" v-for="art_wb of v.art_wb" :key="'art-wb'+art_wb" class="mr-1" variant="primary">-->
                      {{art_wb}}
                    </a>
                  </b-th>
                  <b-th>{{v.barcode}}</b-th>
                  <b-th @click="sendOne(v)"><b-button size="sm" variant="primary">Подходит</b-button></b-th>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

export default {
  name: "quiz-home",
  data: () => ({
    isLoading: true,
    isSending: false,
    one: {},
    allModels: [],
    selModel: '',
    // allRadius: [],
    selRadius: '',
    vip: [],
    findModel: '',
    stats: {},
    last: '',
    lastId: '',
    mostPopular: [],
    mostMaybe: ['Acuvue Oasys 6', 'Acuvue Oasys 12','Acuvue Oasys 24'],

  }),
  methods: {
    alertMsg(title, message, style, append = false) {
      this.$bvToast.toast(`${message}`, {
        title: `${title}`,
        toaster: 'b-toaster-top-right',
        variant: style,
        solid: true,
        appendToast: append
      })
    },
    getOne() {
      this.isLoading=true
      fetch(`${process.env.VUE_APP_SERVER_API}/quiz/getone`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json', 'dildo-token': this.$cookies.get("dildo-token")}
      })
          .then(resp => resp.json())
          .then(resp => {
            if (resp.status) {
              this.one = resp.item
              this.mostMaybe = resp.item.mostMaybe
              this.isLoading=false
            }
            if (!resp.status) this.alertMsg('Ошибка загрузки', `Ошибка сервера, попробуйте ещё раз ${resp.message}`, 'danger')
          })
    },
    sendOne(vip) {
      this.isSending = true
      fetch(`${process.env.VUE_APP_SERVER_API}/quiz/sendone`, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json', 'dildo-token': this.$cookies.get("dildo-token")},
        body: JSON.stringify({origBracode:vip.barcode, gays:this.one.art_wb, origName: vip.vipName, brand:vip.brand, model:vip.mainModel})
      })
          .then(resp => resp.json())
          .then(resp => {
            this.isSending = false
            if (resp.status) {
              // this.selModel=''
              this.last = resp.message
              this.lastId = resp.id
              // this.alertMsg('Загружаем | Успешно', `Добавлено соответсвие:\n ${resp.message}`, 'success')
              this.getOne()
            }
            if (!resp.status) this.alertMsg('Загружаем | Ошибка', `что-то пошло не так =(\n${resp.message}`, 'error')
          })
    },
    sendErr() {
      this.isSending = true
      fetch(`${process.env.VUE_APP_SERVER_API}/quiz/sendone`, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json', 'dildo-token': this.$cookies.get("dildo-token")},
        body: JSON.stringify({gays:this.one.art_wb, origBracode: 'novip'})
      })
          .then(resp => resp.json())
          .then(resp => {
            this.isSending = false
            if (resp.status) {
              this.alertMsg('Загружаем | Успешно', `Удалили`, 'success')
              this.getOne()
            }
            if (!resp.status) this.alertMsg('Загружаем | Ошибка', `что-то пошло не так =(\n${resp.message}`, 'error')
          })
    },
    sendSkip() {
      this.isSending = true
      fetch(`${process.env.VUE_APP_SERVER_API}/quiz/sendone`, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json', 'dildo-token': this.$cookies.get("dildo-token")},
        body: JSON.stringify({gays:this.one.art_wb, origBracode: 'skip'})
      })
          .then(resp => resp.json())
          .then(resp => {
            this.isSending = false
            if (resp.status) {
              this.alertMsg('Загружаем | Успешно', `Пропускаем`, 'success')
              this.getOne()
            }
            if (!resp.status) this.alertMsg('Загружаем | Ошибка', `что-то пошло не так =(\n${resp.message}`, 'error')
          })
    },
    sendNeedAdd() {
      this.isSending = true
      fetch(`${process.env.VUE_APP_SERVER_API}/quiz/sendone`, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json', 'dildo-token': this.$cookies.get("dildo-token")},
        body: JSON.stringify({gays:this.one.art_wb, origBracode: 'needadd'})
      })
          .then(resp => resp.json())
          .then(resp => {
            this.isSending = false
            if (resp.status) {
              this.alertMsg('Загружаем | Успешно', `Ок, добавим`, 'success')
              this.getOne()
            }
            if (!resp.status) this.alertMsg('Загружаем | Ошибка', `что-то пошло не так =(\n${resp.message}`, 'error')
          })
    },
    sendCancel(lastId) {
      this.isSending = true
      fetch(`${process.env.VUE_APP_SERVER_API}/quiz/sendcancel/${lastId}`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json', 'dildo-token': this.$cookies.get("dildo-token")}
      })
          .then(resp => resp.json())
          .then(resp => {
            this.isSending = false
            if (resp.status) {
              this.alertMsg('Отменили | Успешно', ``, 'success')
              this.lastId=this.last=''
              this.getOne()
            }
            if (!resp.status) this.alertMsg('Отменили | Ошибка', `что-то пошло не так =(\n${resp.message}`, 'error')
          })
    },
    getModels() {
      fetch(`${process.env.VUE_APP_SERVER_API}/quiz/getmodels`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json', 'dildo-token': this.$cookies.get("dildo-token")}
      })
          .then(resp => resp.json())
          .then(resp => {
            if (resp.status) {
              this.allModels = resp.items
            }
            if (!resp.status) this.alertMsg('Ошибка загрузки', `Ошибка сервера, попробуйте ещё раз ${resp.message}`, 'danger')
          })
    },
    getVip(model) {
      fetch(`${process.env.VUE_APP_SERVER_API}/quiz/getvip/${model}`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json', 'dildo-token': this.$cookies.get("dildo-token")}
      })
          .then(resp => resp.json())
          .then(resp => {
            if (resp.status) {
              this.vip = resp.items
              // this.allRadius = Array.from(new Set(resp.items.filter(e=>e.radius).map(e=>e.radius)))
              this.slideToEl()
            }
            if (!resp.status) this.alertMsg('Ошибка загрузки', `Ошибка сервера, попробуйте ещё раз ${resp.message}`, 'danger')
          })
    },
    getStats() {
      fetch(`${process.env.VUE_APP_SERVER_API}/quiz/stats`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json', 'dildo-token': this.$cookies.get("dildo-token")}
      })
          .then(resp => resp.json())
          .then(resp => {
            if (resp.status) {
              this.stats = resp.item
            }
            if (!resp.status) this.alertMsg('Ошибка загрузки', `Ошибка сервера, попробуйте ещё раз ${resp.message}`, 'danger')
          })
    },
    slideToEl() {
      let elem = document.querySelector('#barabar');
      // console.log(window.scrollY)
      // console.log(elem.getBoundingClientRect().y);
      window.scrollTo({
        top: window.scrollY + elem.getBoundingClientRect().y-350,
        behavior: 'smooth'
      });
    },
  },
  watch: {
    'selModel': function () {
      if (!this.mostPopular.includes(this.selModel)) this.mostPopular.unshift(this.selModel)
    },
    'mostPopular':function() {
      if (this.mostPopular) localStorage.setItem('mostPopularQuiz', JSON.stringify(this.mostPopular))
    }
  },
  components: { },
  computed: {
  filteredModels() {
    let fm = this.allModels
    if (this.findModel) fm = fm.filter(e=>e.toLowerCase().indexOf(this.findModel.toLowerCase())+1)
    return fm
  }
  },
  async mounted() {
    const dildoToken = this.$cookies.get("dildo-token");
    // const dildoRole = this.$cookies.get("dildo-role")
    if (!dildoToken) this.$router.push({path: "/login"});
    if (dildoToken) {
      this.mostPopular = JSON.parse(localStorage.getItem('mostPopularQuiz')) || []
      this.getOne()
      this.getModels()
      this.getStats()
    } else {
      this.alertMsg('Ошибка', `нет прав`, 'danger')
    }
  }
};
</script>

<style>

</style>
